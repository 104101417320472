var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"back",on:{"click":function($event){return _vm.back()}}},[_c('back',{attrs:{"size":'20px'}})],1),_c('div',{staticClass:"title"},[_vm._v("About")])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"info"},[_vm._v(" Welcome to Ikotun Connect, the premier social media app designed to bring the vibrant city of Ikotun and it environs, together in a seamless online community. With Ikotun Connect, you'll discover a world of connections, engagement, and exploration right at your fingertips. Here's what you can expect from our feature-packed app: ")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"bottom"},[_vm._v(" This is Ikotun Connect!!! embark on an exciting journey of connection, expression, and discovery within the heart of Lagos, Nigeria. Unleash your creativity, build lasting relationships, and make your voice heard within our dynamic and inclusive Ikotun community. Together, let's create an enriching social media experience that truly captures the essence of Ikotun, Lagos! ")]),_c('div',{staticClass:"foot"},[_vm._v("App Version - v "+_vm._s(_vm.$root.$data.app_version)+" ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box_title"},[_vm._v("Follow and Connect")]),_c('div',{staticClass:"text"},[_vm._v(" Forge meaningful connections by following friends, family, influencers, and people who inspire you within the Ikotun community. Stay updated with their latest posts, activities, and achievements, and foster a strong network of connections. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box_title"},[_vm._v("Direct Messaging")]),_c('div',{staticClass:"text"},[_vm._v(" Seamlessly communicate with your Ikotun Connect connections through our intuitive messaging system. Whether it's a private chat or a group conversation, stay connected and engaged with the vibrant individuals in our community. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box_title"},[_vm._v("Marketplace")]),_c('div',{staticClass:"text"},[_vm._v(" Discover a thriving marketplace within Ikotun Connect, where you can buy and sell a wide range of items. From fashion and accessories to gadgets and collectibles, find hidden gems and connect with buyers and sellers within the local Ikotun area. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box_title"},[_vm._v("Elections")]),_c('div',{staticClass:"text"},[_vm._v(" Get ready to make a difference! Ikotun Connect hosts exciting monthly elections for the positions of President and Vice President within our community. Candidates are selected based on their activity and contributions over the previous months. Participate, cast your vote, and help shape the future of our dynamic Ikotun community. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box_title"},[_vm._v("Join Communities")]),_c('div',{staticClass:"text"},[_vm._v(" Explore and join a variety of communities tailored to your interests within Ikotun Connect. Whether you're passionate about art, music, sports, cooking, or any other topic, you'll find like-minded individuals, engaging discussions, and a wealth of shared knowledge right here in your local Ikotun community. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box_title"},[_vm._v("Engage and Interact")]),_c('div',{staticClass:"text"},[_vm._v(" Like, comment, and share your favorite posts to interact with your connections and foster a sense of community within Ikotun Connect. Show your appreciation for the content that resonates with you and engage in meaningful conversations that span across a multitude of topics specific to Ikotun and Lagos. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box_title"},[_vm._v("Privacy and Security")]),_c('div',{staticClass:"text"},[_vm._v(" We prioritize your privacy and ensure that your personal information remains secure within Ikotun Connect. We provide you with granular control over your privacy settings, giving you peace of mind as you connect and engage within our local community. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box_title"},[_vm._v("Discover and Explore")]),_c('div',{staticClass:"text"},[_vm._v(" Ikotun Connect features powerful algorithms that curate personalized recommendations tailored to your interests. Discover new profiles to follow, exciting communities to join, and trending content that is relevant and specific to the vibrant city of Ikotun, Lagos. ")])])
}]

export { render, staticRenderFns }