<template>
    <div class="about">

        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">About</div>
        </div>
        <div class="main">
            <div class="info">
                Welcome to Ikotun Connect, the premier social media app designed to bring the 
                vibrant city of Ikotun and it environs, together in a seamless online community. 
                With Ikotun Connect, you'll discover a world of connections, engagement, and 
                exploration right at your fingertips. Here's what you can expect from our 
                feature-packed app:
            </div>

            <div class="box">
                <div class="box_title">Follow and Connect</div>
                <div class="text">
                    Forge meaningful connections by following friends, family, influencers, and 
                    people who inspire you within the Ikotun community. Stay updated with their 
                    latest posts, activities, and achievements, and foster a strong network of 
                    connections.
                </div>
            </div>

            <div class="box">
                <div class="box_title">Direct Messaging</div>
                <div class="text">
                    Seamlessly communicate with your Ikotun Connect connections through our intuitive 
                    messaging system. Whether it's a private chat or a group conversation, stay 
                    connected and engaged with the vibrant individuals in our community.
                </div>
            </div>

            <div class="box">
                <div class="box_title">Marketplace</div>
                <div class="text">
                    Discover a thriving marketplace within Ikotun Connect, where you can buy and 
                    sell a wide range of items. From fashion and accessories to gadgets and 
                    collectibles, find hidden gems and connect with buyers and sellers within the 
                    local Ikotun area.
                </div>
            </div>


            <div class="box">
                <div class="box_title">Elections</div>
                <div class="text">
                    Get ready to make a difference! Ikotun Connect hosts exciting monthly elections 
                    for the positions of President and Vice President within our community. 
                    Candidates are selected based on their activity and contributions over the 
                    previous months. Participate, cast your vote, and help shape the future of 
                    our dynamic Ikotun community.
                </div>
            </div>

            <div class="box">
                <div class="box_title">Join Communities</div>
                <div class="text">
                    Explore and join a variety of communities tailored to your interests within 
                    Ikotun Connect. Whether you're passionate about art, music, sports, cooking, or 
                    any other topic, you'll find like-minded individuals, engaging discussions, 
                    and a wealth of shared knowledge right here in your local Ikotun community.
                </div>
            </div>

            <div class="box">
                <div class="box_title">Engage and Interact</div>
                <div class="text">
                    Like, comment, and share your favorite posts to interact with your connections 
                    and foster a sense of community within Ikotun Connect. Show your appreciation 
                    for the content that resonates with you and engage in meaningful conversations 
                    that span across a multitude of topics specific to Ikotun and Lagos.
                </div>
            </div>

            <div class="box">
                <div class="box_title">Privacy and Security</div>
                <div class="text">
                    We prioritize your privacy and ensure that your personal information remains 
                    secure within Ikotun Connect. We provide you with granular control over your 
                    privacy settings, giving you peace of mind as you connect and engage within our 
                    local community.
                </div>
            </div>

            <div class="box">
                <div class="box_title">Discover and Explore</div>
                <div class="text">
                    Ikotun Connect features powerful algorithms that curate personalized recommendations 
                    tailored to your interests. Discover new profiles to follow, exciting 
                    communities to join, and trending content that is relevant and specific to the 
                    vibrant city of Ikotun, Lagos.
                </div>
            </div>

            <div class="bottom">
                This is Ikotun Connect!!! embark on an exciting journey of connection, expression, 
                and discovery within the heart of Lagos, Nigeria. Unleash your creativity, 
                build lasting relationships, and make your voice heard within our dynamic and inclusive 
                Ikotun community. Together, let's create an enriching social media experience that 
                truly captures the essence of Ikotun, Lagos!
            </div>

            <div class="foot">App Version - v {{$root.$data.app_version}} </div>
        </div>
    </div>
</template>

<script>
import back from '../../icons/back.vue';

export default {
    components: {
        back
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
    }
}
</script>

<style scoped>

    .about {
        background-color: white;
    }
    .head {
        display: grid;
        grid-template-columns: calc(50% - 22px) 50%;
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
    }
    .title {
        font-weight: 500;
    }

    .main {
        height: calc(100vh - 51px);
        box-sizing: border-box;
        overflow: auto;
        background-color: #edeef0;
    }
    .info {
        padding: 15px;
        line-height: 25px;
    }

    
    .box {
        margin-bottom: 15px;
        padding: 15px;
        background-color: white;
    }
    .box_title {
        font-weight: 500;
    }
    .text {
        margin-top: 10px;
        font-size: 15px;
        line-height: 22px;
        color: gray;
    }


    .bottom {
        padding: 15px;
        padding-top: 0px;
        font-size: 12px;
        line-height: 20px;
    }
    .foot {
        background: linear-gradient(to right, var(--main), tomato);
        padding: 10px;
        color: white;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
    }
</style>